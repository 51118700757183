import BasicLayout from '@/components/layout/index'
const meta = {
    auth: true
}

const pre = 'setting_'

export default {
    path: '/setting',
    name: 'setting',
    redirect: {
        name: `${pre}config`
    },
    meta,
    component: BasicLayout,
    children: [{
        path: 'config',
        name: `${pre}config`,
        meta: {
            auth: ['setting-config'],
            title: '系统配置'
        },
        component: () =>
            import ('@/views/setting/config')
    }]
}