import BasicLayout from '@/components/layout/index'
const meta = {
    auth: true
}

const pre = 'user_'

export default {
    path: '/user',
    name: 'user',
    redirect: {
        name: `${pre}loginlog`
    },
    meta,
    component: BasicLayout,
    children: [{
        path: 'loginlog',
        name: `${pre}loginlog`,
        meta: {
            auth: ['user-loginlog'],
            title: '登陆日志'
        },
        component: () =>
            import ('@/views/user/loginlog')
    }]
}