import BasicLayout from '@/components/layout/index'
const meta = {
    auth: true
}

const pre = 'room_'

export default {
    path: '/room',
    name: 'room',
    redirect: {
        name: `${pre}list`
    },
    meta,
    component: BasicLayout,
    children: [{
        path: 'list',
        name: `${pre}list`,
        meta: {
            auth: ['room-list'],
            title: '直播间列表'
        },
        component: () =>
            import ('@/views/room/list')
    }, {
        path: 'log',
        name: `${pre}log`,
        meta: {
            auth: ['room-list'],
            title: '直播间记录'
        },
        component: () =>
            import ('@/views/room/log')
    }]
}